import { TFeatureNames } from '../types/FeatureFlags';

export const FEATURE_FLAGS: Record<TFeatureNames, boolean> = {
  VITE_FEATURE_FLAG_PAYMENTS:
    import.meta.env.VITE_FEATURE_FLAG_PAYMENTS === 'true',
};

export const canViewFeature = (featureName: TFeatureNames) => {
  return FEATURE_FLAGS[featureName];
};
