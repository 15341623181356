import z from 'zod';

import { COUPON_DISCOUNT_ENUM } from './FormsSchemas';

export const CouponSchema = z.object({
  id: z.string(),
  isDiscount: z.boolean(),
  name: z.string(),
  discount: z.string().nullable(),
  discountType: z.enum(COUPON_DISCOUNT_ENUM).nullable(),
  image: z.string(),
  description: z.string().nullable(),
  startDate: z.string().trim().nullable(),
  endDate: z.string().trim().nullable(),
  createdAt: z.string(),
  userUsed: z.number(),
  limit: z.number().nullable(),
  isActive: z.boolean(),
  pointsNeeded: z.number().nullable(),
});

export const listCouponsCountResponseValidateSchema = z.number().int();

export const CouponDetailsSchema = z.object({
  id: z.string(),
  isDiscount: z.boolean(),
  name: z.string(),
  discount: z.string().nullable(),
  discountType: z.enum(COUPON_DISCOUNT_ENUM).nullable(),
  image: z.string(),
  couponCode: z.string().nullable(),
  isOnetime: z.boolean(),
  isForAdults: z.boolean(),
  description: z.string().nullable(),
  startDate: z.string().trim().nullable(),
  endDate: z.string().trim().nullable(),
  categories: z.array(z.string()),
  limit: z.number().nullable(),
  userUsed: z.number(),
  isActive: z.boolean(),
});

export const ListCouponsResponseSchema = z.object({
  data: z.array(CouponSchema),
  successCode: z.string(),
  total: z.number(),
});

export const GetCouponResponseSchema = z.object({
  successCode: z.string(),
  data: CouponDetailsSchema,
});

export const UpdatedCouponSchema = z.object({
  id: z.string(),
  name: z.string(),
  discount: z.string(),
  imgUrl: z.string().optional(),
  image: z.string().optional(),
  couponCode: z.string(),
  isOnetime: z.boolean(),
  isForAdults: z.boolean(),
  description: z.string().nullable().optional(),
  startDate: z.string().nullable().optional(),
  endDate: z.string().nullable().optional(),
  categories: z.array(z.string()),
});

export const ScannedCouponSchema = z.object({
  couponUsesId: z.string().optional(),
  name: z.string(),
  description: z.string().optional().nullable(),
  discount: z.string(),
  id: z.string(),
  image: z.string(),
  isOnetime: z.boolean(),
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
});

export const ScannedCouponResponseSchema = z.object({
  successCode: z.string(),
  data: ScannedCouponSchema,
});

export const CreateCouponItemResponseSchema = z.object({
  categories: z.array(z.string()).optional(),
  isDiscount: z.boolean(),
  couponCode: z.string().nullable(),
  createdAt: z.string(),
  description: z.string(),
  discount: z.string().nullable(),
  endDate: z.string().nullable(),
  id: z.string(),
  image: z.string(),
  isForAdults: z.boolean(),
  isOnetime: z.boolean(),
  name: z.string(),
  placeId: z.string(),
  startDate: z.string().nullable(),
  updatedAt: z.string(),
  userUsed: z.number(),
  limit: z.number().nullable(),
  isActive: z.boolean(),
});

export const CreateCouponResponseSchema = z.object({
  successCode: z.string(),
  data: CreateCouponItemResponseSchema,
});

export const DeleteCouponResponseSchema = z.object({
  successCode: z.string(),
  data: z.literal(true),
});

export const AcceptCouponResponseSchema = z.object({
  successCode: z.string(),
  data: z.literal(true),
});
