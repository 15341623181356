import { useTranslation } from 'react-i18next';

import Button from '../Button/Button';

const InvoicesSection = () => {
  const { t } = useTranslation();

  // TODO: Replace dummy data with real data from backend
  const isLoading = false;

  return (
    <div className="w-full rounded-xs border p-6">
      <p className="text-lg font-semibold">{t('messages.invoices')}</p>
      <p className="mb-6 mt-4">{t('messages.viewAllIssuedInvoices')}</p>
      <div className="mt-6 flex justify-end">
        <Button
          type="button"
          text={t('messages.viewListOfInvoices')}
          variant="secondary"
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

export default InvoicesSection;
