import { SVGProps } from 'react';

const PaymentsIcon = ({ className }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833374 16.827V5.85914H2.625V15.0354H16.7724V16.827H0.833374ZM3.83333 13.8687V3.3335H19.3397V13.8687H3.83333ZM7.38137 12.0771C7.38137 11.5941 7.20939 11.1806 6.86544 10.8366C6.52146 10.4926 6.10798 10.3207 5.62498 10.3207V12.0771H7.38137ZM15.7916 12.0771H17.548V10.3207C17.063 10.3207 16.649 10.4926 16.3061 10.8366C15.9631 11.1806 15.7916 11.5941 15.7916 12.0771ZM11.5804 10.6844C12.1667 10.6844 12.6581 10.4807 13.0544 10.0734C13.4508 9.66601 13.649 9.16831 13.649 8.58027C13.649 8.00379 13.4493 7.51593 13.0501 7.11668C12.6508 6.71743 12.163 6.51781 11.5865 6.51781C10.9985 6.51781 10.5008 6.71599 10.0934 7.11235C9.68605 7.50871 9.48237 8.00006 9.48237 8.58639C9.48237 9.17274 9.68546 9.669 10.0916 10.0752C10.4978 10.4813 10.994 10.6844 11.5804 10.6844ZM5.62498 6.88154C6.10798 6.88154 6.52146 6.70956 6.86544 6.3656C7.20939 6.02163 7.38137 5.60814 7.38137 5.12514H5.62498V6.88154ZM17.548 6.88154V5.12514H15.7916C15.7916 5.61018 15.9636 6.02418 16.3076 6.36712C16.6515 6.71007 17.065 6.88154 17.548 6.88154Z"
        fill="#B3ADC1"
        className={className}
      />
    </svg>
  );
};

export default PaymentsIcon;
