import { createSlice } from '@reduxjs/toolkit';

import { ICouponSliceState } from '../../types/Coupon';
import {
  acceptCouponThunk,
  createCouponThunk,
  deleteCouponThunk,
  getCouponThunk,
  listCouponsCountThunk,
  listCouponThunk,
  scanCouponThunk,
  updateCouponThunk,
} from '../thunks/couponThunk';

const initialState: ICouponSliceState = {
  listCouponsCountThunkStatus: null,
  total: 0,
  coupon: null,
  currentCouponId: null,
  couponStatus: null,
  coupons: null,
  listCouponStatus: null,
  scannedCoupon: null,
  categories: null,
  scannedCouponStatus: null,
  acceptCouponStatus: null,
  createCouponStatus: null,
  updateCouponStatus: null,
  deleteCouponStatus: null,
};

export const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    clearCoupons: () => initialState,
    setCurrentCoupon: (state, { payload }) => {
      state.coupon = payload;
    },
    setCurrentCouponId: (state, { payload }) => {
      state.currentCouponId = payload;
    },
    resetDeleteCouponStatus: (state) => {
      state.deleteCouponStatus = null;
    },
  },
  extraReducers: (builder) => {
    // listCoupons
    builder.addCase(listCouponThunk.pending, (state) => {
      state.listCouponStatus = 'loading';
    });
    builder.addCase(listCouponThunk.fulfilled, (state, { payload }) => {
      state.coupons = payload;
      state.listCouponStatus = 'success';
    });
    builder.addCase(listCouponThunk.rejected, (state) => {
      state.listCouponStatus = 'failed';
    });

    // getCoupon
    builder.addCase(getCouponThunk.pending, (state) => {
      state.couponStatus = 'loading';
    });
    builder.addCase(getCouponThunk.fulfilled, (state, { payload }) => {
      state.coupon = payload;
      state.couponStatus = 'success';
    });
    builder.addCase(getCouponThunk.rejected, (state) => {
      state.couponStatus = 'failed';
    });

    // createCoupon
    builder.addCase(createCouponThunk.pending, (state) => {
      state.createCouponStatus = 'loading';
    });
    builder.addCase(createCouponThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.coupons) return;
      const { coupon, selectedListVariant } = payload;

      const newCoupon = { ...coupon, pointsNeeded: null, discountType: null };

      const shouldRenderInList =
        (coupon.isDiscount &&
          coupon.isActive &&
          selectedListVariant === 'active') ||
        (coupon.isDiscount &&
          !coupon.isActive &&
          selectedListVariant === 'inactive') ||
        (!coupon.isDiscount && selectedListVariant === 'news') ||
        selectedListVariant === 'all';

      if (shouldRenderInList) {
        state.coupons.data.unshift(newCoupon);
        state.total += 1;
      }

      state.createCouponStatus = 'success';
    });
    builder.addCase(createCouponThunk.rejected, (state) => {
      state.createCouponStatus = 'failed';
    });

    // updateCoupon
    builder.addCase(updateCouponThunk.pending, (state) => {
      state.updateCouponStatus = 'loading';
    });
    builder.addCase(updateCouponThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.coupons) return;

      const { coupon, selectedListVariant } = payload;

      const shouldRenderInList =
        (coupon.isDiscount &&
          coupon.isActive &&
          selectedListVariant === 'active') ||
        (coupon.isDiscount &&
          !coupon.isActive &&
          selectedListVariant === 'inactive') ||
        (!coupon.isDiscount && selectedListVariant === 'news') ||
        selectedListVariant === 'all';

      if (shouldRenderInList) {
        state.coupons.data = state.coupons.data.map((c) => {
          if (c.id === coupon.id) {
            return { ...c, ...coupon };
          }
          return c;
        });
      } else {
        state.coupons.data = state.coupons.data.filter(
          (c) => c.id !== coupon.id,
        );
        state.total -= 1;
      }

      state.updateCouponStatus = 'success';
    });
    builder.addCase(updateCouponThunk.rejected, (state) => {
      state.updateCouponStatus = 'failed';
    });

    // deleteCoupon
    builder.addCase(deleteCouponThunk.pending, (state) => {
      state.deleteCouponStatus = 'loading';
    });
    builder.addCase(deleteCouponThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.coupons) return;

      state.coupons.data = state.coupons.data.filter(
        (coupon) => coupon.id !== payload,
      );

      state.total -= 1;
      state.deleteCouponStatus = 'success';
    });
    builder.addCase(deleteCouponThunk.rejected, (state) => {
      state.deleteCouponStatus = 'failed';
    });

    // scanCoupon
    builder.addCase(scanCouponThunk.pending, (state) => {
      state.scannedCouponStatus = 'loading';
    });
    builder.addCase(scanCouponThunk.fulfilled, (state, { payload }) => {
      state.scannedCoupon = payload;
      state.scannedCouponStatus = 'success';
    });
    builder.addCase(scanCouponThunk.rejected, (state) => {
      state.scannedCouponStatus = 'failed';
    });

    // acceptCoupon
    builder.addCase(acceptCouponThunk.pending, (state) => {
      state.acceptCouponStatus = 'loading';
    });
    builder.addCase(acceptCouponThunk.fulfilled, (state, { payload }) => {
      if (!payload || !state.coupons?.data) return;
      state.coupons.data = state.coupons.data.map((coupon) => {
        if (coupon.id === payload) {
          return { ...coupon, userUsed: coupon.userUsed + 1 };
        }
        return coupon;
      });
      state.acceptCouponStatus = 'success';
    });
    builder.addCase(acceptCouponThunk.rejected, (state) => {
      state.acceptCouponStatus = 'failed';
    });

    // listCouponsCountThunk
    builder.addCase(listCouponsCountThunk.pending, (state) => {
      state.listCouponsCountThunkStatus = 'loading';
    });
    builder.addCase(listCouponsCountThunk.fulfilled, (state, { payload }) => {
      state.total = payload;
      state.listCouponsCountThunkStatus = 'success';
    });
    builder.addCase(listCouponsCountThunk.rejected, (state) => {
      state.listCouponsCountThunkStatus = 'failed';
    });
  },
});

export const {
  clearCoupons,
  resetDeleteCouponStatus,
  setCurrentCouponId,
  setCurrentCoupon,
} = couponSlice.actions;

export default couponSlice.reducer;
