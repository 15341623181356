import { useTranslation } from 'react-i18next';

import Button from '../Button/Button';
import useModal from '../../../../hooks/useModal';
import InvoiceModal from '../../organisms/InvoiceModal/InvoiceModal';
import InvoiceDataSectionSkeleton from '../../atoms/InvoiceDataSectionSkeleton/InvoiceDataSectionSkeleton';

const InvoiceDataSection = () => {
  const { t } = useTranslation();

  // TODO: Replace dummy data with real data from backend

  const isLoading = false;
  const firstName = 'Jan';
  const lastName = 'Kowalski';
  const nip = '123-45-67-890';
  const companyName = 'CA';
  const companyAddress = 'Marszałkowska 123, 00-000 Warszawa';

  const { visible, showModal, hideModal } = useModal();

  return (
    <div className="w-full rounded-xs border p-6">
      <InvoiceModal visible={visible} onClose={hideModal} />
      <p className="text-lg font-semibold">{t('messages.invoiceData')}</p>
      {isLoading ? (
        <InvoiceDataSectionSkeleton />
      ) : (
        <div className="mt-4 flex flex-col gap-8">
          <div className="flex w-full flex-col md:flex-row">
            <div className="flex w-full flex-col gap-8 xs:flex-row xs:justify-between">
              <div className="flex w-[50%] flex-col gap-4 font-medium">
                <span className="text-sm">{t('messages.firstName')}</span>
                <span>{firstName}</span>
              </div>

              <div className="flex w-[50%] flex-col gap-4 font-medium">
                <span className="text-sm">{t('messages.lastName')}</span>
                <span>{lastName}</span>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col md:flex-row">
            <div className="flex w-full flex-col gap-8 xs:flex-row xs:justify-between">
              <div className="flex w-[50%] flex-col gap-4 font-medium">
                <span className="text-sm">{t('messages.email')}</span>
                <span>{firstName}</span>
              </div>

              <div className="flex w-[50%] flex-col gap-4 font-medium">
                <span className="text-sm">NIP</span>
                <span>{nip}</span>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col md:flex-row">
            <div className="flex w-full flex-col gap-8 xs:flex-row xs:justify-between">
              <div className="flex w-[50%] flex-col gap-4 font-medium">
                <span className="text-sm">{t('messages.companyName')}</span>
                <span>{companyName}</span>
              </div>

              <div className="flex w-[50%] flex-col gap-4 font-medium">
                <span className="text-sm">{t('messages.companyAddress')}</span>
                <span>{companyAddress}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-6 flex justify-end">
        <Button
          type="button"
          text={t('messages.edit')}
          variant="secondary"
          onClick={showModal}
          isDisabled={isLoading}
          isSkeleton={isLoading}
        />
      </div>
    </div>
  );
};

export default InvoiceDataSection;
