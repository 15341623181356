import PaymentPlanSection from '../../UI/molecules/PaymentPlanSection/PaymentPlanSection';
import InvoiceDataSection from '../../UI/molecules/InvoiceDataSection/InvoiceDataSection';
import InvoicesSection from '../../UI/molecules/InvoicesSection/InvoicesSection';

const PaymentPage = () => {
  return (
    <div className="h-full w-full overflow-y-auto pr-2">
      <div className="flex flex-col gap-8 lg:flex-row">
        <PaymentPlanSection />
        <div className="flex w-full max-w-[1000px] flex-col gap-8">
          <InvoiceDataSection />
          <InvoicesSection />
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
