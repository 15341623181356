import CalendarIcon from '../assets/icons/CalendarIcon';
import CouponIcon from '../assets/icons/CouponIcon';
import SettingsIcon from '../assets/icons/SettingsIcon';
import MenuIcon from '../assets/icons/MenuIcon';
import ChartIcon from '../assets/icons/ChartIcon';
import ClockIcon from '../assets/icons/ClockIcon';
import TableIcon from '../assets/icons/TableIcon';
import HolidaysIcon from '../assets/icons/HolidaysIcon';
import AvailabilityIcon from '../assets/icons/AvailabilityIcon';
import HomeIcon from '../assets/icons/HomeIcon';
import { IPredefinedTable } from '../components/UI/atoms/PredefinedTable/PredefinedTable.types';
import EventIcon from '../assets/icons/EventIcon';
import ReservationsTabIcon from '../assets/icons/ReservationsTabIcon';
import UnseenMessagesTabIcon from '../assets/icons/UnseenMessagesTabIcon';
import { IOption } from '../components/UI/organisms/ReservationsPanel/ReservationsPanel.types';
import SupportIcon from '../assets/icons/SupportIcon';
import GuestBookIcon from '../assets/icons/GuestBookIcon';
import PaymentsIcon from '../assets/icons/PaymentsIcon';
import { canViewFeature } from '../functions/featureFlags';

export const routes = {
  home: '/',
  summary: '/summary',
  reservations: '/reservations',
  availability: '/availability',
  availabilitySpacePlan: '/availability/space-plan',
  availabilitySchedule: '/availability/schedule',
  availabilitySpecialDays: '/availability/special-days',
  availabilitySpecialDaysSchedule: '/availability/special-days/schedule',
  coupons: '/coupons',
  events: '/events',
  menu: '/menu',
  offer: '/offers',
  hallManager: '/hall-manager',
  employees: '/employees',
  statistics: '/statistics',
  settings: '/settings',
  login: '/login',
  passwordRecovery: '/password-recovery',
  newPassword: '/password-change',
  firstSteps: '/first-steps',
  congratulations: '/congratulations',
  empty: '*',
  reservationsUnseen: '/reservations/unseen',
  reservationsNeedAction: '/reservations/need-action',
  support: '/support',
  guestBook: '/guest-book',
  guestBookBookingHistory: 'booking-history',
  guestBookDetails: 'details',
  payments: '/payments',
};

export const reduxStatus = {
  loading: 'loading',
  success: 'success',
  rejected: 'rejected',
};

export const RESERVATION_STATUSES = [
  'NEW',
  'SMS_CONFIRMED',
  'DELAYED',
  'STARTED',
  'CANCELLED',
  'FINISHED',
] as const;

export const daysOfWeek = [
  { label: 'monday', value: 1, name: 'monday' },
  { label: 'tuesday', value: 2, name: 'tuesday' },
  { label: 'wednesday', value: 3, name: 'wednesday' },
  { label: 'thursday', value: 4, name: 'thursday' },
  { label: 'friday', value: 5, name: 'friday' },
  { label: 'saturday', value: 6, name: 'saturday' },
  { label: 'sunday', value: 0, name: 'sunday' },
];

export const pickerColorsBackground = [
  'bg-purple-500',
  'bg-indigo-500',
  'bg-blue-600',
  'bg-pink-500',
  'bg-teal-400',
  'bg-green-400',
  'bg-yellow-300',
  'bg-orange-400',
  'bg-red-500',
];

export const pickerColors = [
  'purple',
  'indigo',
  'blue',
  'pink',
  'teal',
  'green',
  'yellow',
  'orange',
  'red',
];

export const averageReceiptsOptions = [
  { value: '$', label: 'to50' },
  { value: '$$', label: 'from50to200' },
  { value: '$$$', label: 'from200' },
];

export const userRoles = {
  admin: 'ADMIN',
  manager: 'MANAGER',
  employee: 'EMPLOYEE',
};

export const sideMenuOptions = [
  [
    {
      label: 'summary',
      route: routes.summary,
      icon: HomeIcon,
    },
    {
      label: 'reservations',
      route: routes.reservations,
      icon: CalendarIcon,
    },
    {
      label: 'guestBook',
      route: routes.guestBook,
      icon: GuestBookIcon,
    },
    {
      label: 'coupons',
      route: routes.coupons,
      icon: CouponIcon,
    },
    {
      label: 'events',
      route: routes.events,
      icon: EventIcon,
    },
    {
      label: 'offers',
      route: routes.offer,
      icon: MenuIcon,
    },
    {
      label: 'statistics',
      route: routes.statistics,
      icon: ChartIcon,
    },
  ],
  [
    {
      label: 'availability',
      route: routes.availabilitySpacePlan,
      icon: AvailabilityIcon,
    },
    {
      label: 'settings',
      route: routes.settings,
      icon: SettingsIcon,
    },
    ...(canViewFeature('VITE_FEATURE_FLAG_PAYMENTS')
      ? [
          {
            label: 'payments',
            route: routes.payments,
            icon: PaymentsIcon,
          },
        ]
      : []),
  ],
  [
    {
      label: 'supportModule.support',
      route: routes.support,
      icon: SupportIcon,
    },
  ],
];

export const availabilityTemplateTabs = [
  {
    label: 'spacePlan',
    route: routes.availabilitySpacePlan,
    icon: TableIcon,
  },
  {
    label: 'schedule',
    route: routes.availabilitySchedule,
    icon: ClockIcon,
  },
  {
    label: 'specialDays',
    route: routes.availabilitySpecialDays,
    icon: HolidaysIcon,
  },
];

export const reservationsTabs = [
  {
    label: 'reservations',
    route: routes.reservations,
    icon: ReservationsTabIcon,
  },
  // TODO uncomment if blocking system will be returned
  // {
  //   label: 'contactWithCustomerRequired',
  //   route: routes.reservationsNeedAction,
  //   icon: ContactNeededMessagesTabIcon,
  // },
  {
    label: 'unreadMessages',
    route: routes.reservationsUnseen,
    icon: UnseenMessagesTabIcon,
  },
];

export const TOAST_TWO_SECONDS_DURATION = {
  autoClose: 2000,
};

export const TOAST_THREE_SECONDS_DURATION = {
  autoClose: 3000,
};

export const INITIAL_TIMEZONE = 'Europe/Warsaw';

export const apiErrorMessages = {
  INTERNAL: 'INTERNAL',
  SPECIAL_DAY_EXISTS: 'SPECIAL_DAY_EXISTS',
  RESERVATION_ERRORS: 'RESERVATION_ERRORS',
  UNAUTHORIZED: 'UNAUTHORIZED',
  CHECKOUT_EXISTS: 'CHECKOUT_EXISTS',
  SUBSCRIPTION_EXISTS: 'SUBSCRIPTION_EXISTS',
  NO_SUBSCRIPTION: 'NO_SUBSCRIPTION',
  COLLIDING_RESERVATION: 'COLLIDING_RESERVATION',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  USER_EXISTS: 'USER_EXISTS',
  EMAIL_TAKEN: 'EMAIL_TAKEN',
  OPENING_HOURS_NOT_MATCH: 'OPENING_HOURS_NOT_MATCH',
  AVAILABILITY_COLLISION_WITH_SPECIAL_DAY_OPENING_HOURS:
    'AVAILABILITY_COLLISION_WITH_SPECIAL_DAY_OPENING_HOURS',
};

export const imageExtensionsUpload = {
  'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
};

export const GRID_CELL_SIZE: number = 20;

export const predefinedTables: IPredefinedTable[] = [
  {
    id: 1,
    shape: 'CIRCLE',
    minSeats: 1,
    maxSeats: 1,
    rotate: 0,
    imageSeats: 1,
  },
  {
    id: 2,
    shape: 'CIRCLE',
    minSeats: 1,
    maxSeats: 2,
    rotate: 0,
    imageSeats: 2,
  },
  {
    id: 3,
    shape: 'CIRCLE',
    minSeats: 1,
    maxSeats: 4,
    rotate: 0,
    imageSeats: 4,
  },
  {
    id: 5,
    shape: 'CIRCLE',
    minSeats: 1,
    maxSeats: 8,
    rotate: 0,
    imageSeats: 8,
  },
  {
    id: 6,
    shape: 'SQUARE',
    minSeats: 1,
    maxSeats: 1,
    rotate: 0,
    imageSeats: 1,
  },
  {
    id: 7,
    shape: 'SQUARE',
    minSeats: 1,
    maxSeats: 2,
    rotate: 0,
    imageSeats: 2,
  },
  {
    id: 8,
    shape: 'SQUARE',
    minSeats: 1,
    maxSeats: 4,
    rotate: 0,
    imageSeats: 4,
  },
  {
    id: 9,
    shape: 'RECTANGLE',
    minSeats: 1,
    maxSeats: 2,
    rotate: 0,
    imageSeats: 2,
  },
  {
    id: 10,
    shape: 'RECTANGLE',
    minSeats: 1,
    maxSeats: 4,
    rotate: 0,
    imageSeats: 4,
  },
  {
    id: 11,
    shape: 'RECTANGLE',
    minSeats: 1,
    maxSeats: 6,
    rotate: 0,
    imageSeats: 6,
  },
  {
    id: 12,
    shape: 'RECTANGLE',
    minSeats: 1,
    maxSeats: 8,
    rotate: 0,
    imageSeats: 8,
  },
  {
    id: 13,
    shape: 'RECTANGLE',
    minSeats: 1,
    maxSeats: 10,
    rotate: 0,
    imageSeats: 10,
  },
];

export const BREAKPOINTS = {
  xsMobile: 320,
  mobile: 768,
  tablet: 1280,
  lgBeforeXl: 1100,
  xl: 1200,
  xlToXxl: 1420,
};

export const canvaTools = {
  zoomIn: 'zoomIn',
  zoomOut: 'zoomOut',
  cursor: 'cursor',
  move: 'move',
};

export const MAX_DRAWER_HEIGHT_PERCENT = 0.75;

export const TIMEZONES = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg',
];

export const numberOfPeopleArray = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
];

export const EVENT_CATEGORIES = [
  'concertsAndMusic',
  'theatreAndPerformingArts',
  'sportsAndRecreation',
  'communityEvents',
  'educationAndWorkshops',
  'filmAndCinema',
  'gastronomyAndTastings',
  'artAndExhibitions',
  'fashionAndBeauty',
  'others',
] as const;

export const OFFER_CATEGORIES = [
  'categoryAlcoholCard',
  'categoryBeverageCard',
  'categoryBilards',
  'categoryBowlingLanes',
  'categoryBreakfasts',
  'categoryDesserts',
  'categoryEscapeRooms',
  'categoryGames',
  'categoryLunch',
  'categoryMenu',
  'categoryPartiesAndEvents',
  'categoryPrivateLodges',
  'categorySnacks',
  'categoryVipZone',
  'categoryOther',
] as const;

export const specialFeatures: IOption[] = [
  {
    id: 1,
    name: 'parking',
    option: 'parking',
  },
  {
    id: 2,
    name: 'facilitiesForTheDisabled',
    option: 'facilitiesForTheDisabled',
  },
  {
    id: 3,
    name: 'wifi',
    option: 'wifi',
  },
  {
    id: 4,
    name: 'veganDishes',
    option: 'veganDishes',
  },
  {
    id: 5,
    name: 'vegetarianDishes',
    option: 'vegetarianDishes',
  },
  {
    id: 6,
    name: 'dogFriendly',
    option: 'dogFriendly',
  },
];

export const LANGUAGES = [
  {
    label: 'EN',
    value: 'en',
  },
  {
    label: 'PL',
    value: 'pl',
  },
];

export const STATUS_COLORS = {
  purple: '#5521B5',
  slateBlue: '#5850EC',
  blue: '#1C64F2',
  pink: '#D61F69',
  cyan: '#0694A2',
  green: '#0E9F6E',
  yellow: '#FACA15',
  golden: '#C27803',
  wheat: '#FF8A4C',
  orange: '#FF5A1F',
  coral: '#F05252',
  red: '#E02424',
  slate: '#5E586C',
};

export const REST_API_URLS = {
  getEmployee: (firebaseId: string) => `/employee/${firebaseId}`,
  getPlaceBaseSettings: '/place/get-place-base-settings',
  getPlaceFirstStepsFormValues: '/place/get-place-first-steps-form-values',
  updatePlace: '/place/update-place',
  updateTempPassword: '/employee/update-temp-password',
  getPlaceForSettings: '/place/get-place-for-settings',
  geoapifyAutocomplete: '/proxy/geoapify-autocomplete',
  getCurrentSmsLimit: 'place/get-currentSMS',
  // coupons
  listCoupons: '/coupon/list-coupons',
  getCoupon: '/coupon/get-coupon',
  createCoupon: '/coupon/create-coupon',
  updateCoupon: '/coupon/update-coupon',
  deleteCoupon: '/coupon/delete-coupon',
  scanCoupon: '/coupon/scan-coupon',
  acceptCoupon: '/coupon/accept-coupon',
  // events
  listEvents: '/event/list-events',
  getEvent: '/event/get-event',
  createEvent: '/event/create-event',
  updateEvent: '/event/update-event',
  deleteEvent: '/event/delete-event',
  // menu
  createOfferCategory: '/offer/create-offer-category',
  listOffersAdmin: '/offer/list-offers-admin',
  listOfferCategory: '/offer/list-offer-category',
  getOffer: '/offer/get-offer',
  setOfferStatus: '/offer/set-offer-status',
  deleteOfferCategory: '/offer/delete-offer-category',
  updateOffers: '/offer/update-offers',
  updateOffer: '/offer/update-offer',
  updateOfferCategories: '/offer/update-offer-categories',
  listProducts: '/product/list-products',
  createProduct: '/product/create-product',
  updateProduct: '/product/update-product',
  deleteProduct: '/product/delete-product',
  updateProductOrder: '/offer/update-product-order',
  // employees
  createEmployee: '/employee/create-employee',
  listEmployees: '/employee/list-employees',
  updateEmployee: '/employee/update-employee',
  deleteEmployee: '/employee/delete-employee',
  listReservations: '/reservation/list-reservations',
  // space plan
  listSpacePlans: '/space-plan/list-space-plans',
  createSpacePlan: '/space-plan/create-space-plan',
  createDefaultSpacePlan: '/space-plan/create-default-space-plan',
  updateSpacePlan: '/space-plan/update-space-plan',
  deleteSpacePlan: '/space-plan/delete-space-plan',
  setSpacePlanStatus: '/space-plan/set-space-plan-status',
  // special days
  listSpecialDays: '/special-day/list-special-days',
  createSpecialDay: '/special-day/create-special-day',
  updateSpecialDay: '/special-day/update-special-day',
  deleteSpecialDay: '/special-day/delete-special-day',
  createSpecialDayAvailability: '/special-day/create-special-day-availability',
  updateSpecialDayAvailability: '/special-day/update-special-day-availability',
  deleteSpecialDayAvailability: '/special-day/delete-special-day-availability',
  listSpecialDaysAvailability: '/special-day/list-special-days-availability',
  listSpecialDaysCount: 'special-day/list-special-days-count',
  listCouponsCount: 'coupon/list-coupons-count',
  listEventsCount: 'event/list-events-count',
  specialDayListSpecialDaysAvailabilities:
    '/special-day/list-special-days-availabilities',
  specialDayUpdateSpecialDayAvailability:
    '/special-day/update-special-day-availability',
  specialDayDeleteSpecialDayAvailability:
    '/special-day/delete-special-day-availability',
  importSpecialDayAvailabilities:
    '/special-day/import-special-day-availabilities',
  // schedule
  listAvailabilities: '/schedule/list-availabilities',
  createAvailability: '/schedule/create-availability',
  updateAvailability: '/schedule/update-availability',
  deleteAvailability: '/schedule/delete-availability',
  // tables
  listTables: '/table/list-tables',
  addTables: '/table/add-tables',
  updateTable: '/table/update-table',
  deleteTable: '/table/delete-table',
  listFreeTables: '/table/list-free-tables-admin',
  // hour slot
  listHourSlots: 'hour-slot/list-hour-slots-data-modal-admin',
  // reservations
  createReservationAdmin: '/reservation/create-reservation-admin',
  createReservationWalkIn: '/reservation/create-reservation-walkin',
  updateReservationAdmin: '/reservation/update-reservation-admin',
  cancelReservationAdmin: '/reservation/cancel-reservation-admin',
  checkReservation: '/reservation/check-reservation',
  getTotalReservationsCount: '/reservation/list-reservations-total',
  getReservation: '/reservation/get-reservation-admin',
  // statistics
  statisticsList: '/statistics/list',
  // summary
  startReservation: '/reservation/start-reservation-admin',
  finishReservation: '/reservation/finish-reservation-admin',
  sendSms: '/reservation/send-sms-reservation-admin',
  // chat
  listMessage: '/chat/message-list-admin',
  sendMessage: '/chat/send-message-admin',
  markMessagesAsSeen: '/chat/messages-seen-admin',
  isAnyUnseenMessage: '/chat/is-any-unseen-message',
  // ticket/support
  createSupportTicket: '/ticket/create-support-ticket',
  // guest book
  listClients: '/clients/client-list',
  listClientsTotal: '/clients/client-list-total',
  getClient: 'clients/get-client',
  createClient: '/clients/create-client',
  updateClient: '/clients/update-client',
  deleteClient: '/clients/delete-client',
  searchClient: '/clients/search-client',
  searchClientTotal: '/clients/search-client-total',
  listClientReservations: '/clients/list-client-reservations',
  listClientReservationsTotal: '/clients/list-client-reservations-total',
  addTags: '/clients/add-tags',
  updateTags: '/clients/update-tags',
};

export const couponCategories = {
  communityEvents: { name: 'communityEvents' },
  educationAndWorkshops: { name: 'educationAndWorkshops' },
  filmAndCinema: { name: 'filmAndCinema' },
  gastronomyAndTastings: { name: 'gastronomyAndTastings' },
  artAndExhibitions: { name: 'artAndExhibitions' },
  fashionAndBeauty: { name: 'fashionAndBeauty' },
  sportsAndRecreation: { name: 'sportsAndRecreation' },
};

export const EMPLOYEE_ROLES = ['ADMIN', 'MANAGER', 'EMPLOYEE'] as const;

export const employeeRolePriority = {
  ADMIN: 0,
  MANAGER: 1,
  EMPLOYEE: 2,
} as const;

export const imagePaths = {
  products: 'products',
};

export const reservationSlotIntervals = [15, 30, 45, 60];

export const timeLimitBeforeReservationIntervals = [
  15, 30, 45, 60, 120, 180, 240,
];

export const RESERVATION_LANGUAGES = [
  {
    label: 'Polski',
    value: 'pl',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Українська',
    value: 'uk',
  },
];

export const STANDARD_SCHEDULE_NUMBER_OF_DAY = 7;

export const weekDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;

export const DEFAULT_OPENING_HOURS = {
  monday: { open: '08:00', close: '16:00' },
  tuesday: { open: '08:00', close: '16:00' },
  wednesday: { open: '08:00', close: '16:00' },
  thursday: { open: '08:00', close: '16:00' },
  friday: { open: '08:00', close: '16:00' },
  saturday: { open: '08:00', close: '16:00' },
  sunday: { open: '08:00', close: '16:00' },
};

export const SMS_USAGE_HIGH = 75;
export const SMS_USAGE_LIMIT = 90;

export const SUPPORT_PHONE_NUMBER = '+48 785 555 505';
export const SUPPORT_EMAIL = 'umowa@funzy.app';

export const RECURRENCE_PERIODS = [
  'doesNotRepeat',
  'everyWeekday',
  'daily',
  'weekly',
  'monthly',
  'yearly',
  'custom',
] as const;

export const RECURRENCE_REPEAT_OPTIONS = [
  'daily',
  'weekly',
  'monthly',
  'yearly',
] as const;

export const RECCURENCE_TYPES = [
  'daily',
  'weekly',
  'monthly',
  'yearly',
] as const;

export const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;

export const MONTHLY_RECCURENCE_OPTIONS = [
  { label: 'first', value: '1' },
  { label: 'second', value: '2' },
  { label: 'third', value: '3' },
  { label: 'fourth', value: '4' },
  { label: 'last', value: '5' },
] as const;

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const DEFAULT_WEEEK_DAYS = [1, 2, 3, 4, 5];

export const COUPON_LIST_VARIANTS = [
  'active',
  'inactive',
  'special',
  'news',
  'all',
] as const;

export const FLAG_CDN_URL = 'https://flagcdn.com/32x24';

export const MEASUREMENT_UNITS = [
  'ml',
  'l',
  'g',
  'kg',
  'pcs',
  'servings',
  'minutes',
  'h',
  'person',
  'singleDay',
  'session',
] as const;

export const NON_VARYING_UNITS = ['g', 'ml', 'l', 'kg'];
