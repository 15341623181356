import { t } from 'i18next';
import z from 'zod';

import {
  emailRegex,
  encodedNameRegex,
  fullNameRegex,
  nipRegex,
  urlRegex,
} from '../constants/regex';

export const CreateTablesGroupFormSchema = z.object({
  name: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  spacePlanId: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
});

export const AddTableToGroupFormSchema = z.object({
  tableId: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
});

export const DropdownCheckboxesSchema = z.object({
  id: z.string(),
  name: z.string(),
  option: z.string(),
});

export const LanguageSelectorSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const COUPON_DISCOUNT_ENUM = [
  'PERCENT',
  'PRICE',
  'TWO_FOR_ONE',
  'THREE_FOR_TWO',
  'FREE',
] as const;

const BaseCouponFieldsSchema = {
  usageType: z.enum(['coupon', 'news']),
  image: z
    .union([z.instanceof(File), z.string(), z.null()])
    .refine((data) => !!data, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
  name: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  description: z.string(),
  category: z.array(DropdownCheckboxesSchema).optional(),
  startDate: z.optional(z.date()),
  endDate: z.optional(z.date()),
  isForAdults: z.boolean(),
};

const CouponOnlyFieldsSchema = {
  discountType: z.enum(COUPON_DISCOUNT_ENUM),
  discount: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  isOnetime: z.enum(['single', 'multiple']),
  maxNumberOfCoupons: z
    .string()
    .nullable()
    .refine((val) => val !== null && parseInt(val, 10) > 0, {
      message: t('errorMessages.mustBePositiveNumber'),
    })
    .refine((val) => val !== null && Number.isInteger(parseInt(val, 10)), {
      message: t('errorMessages.mustBeAnInteger'),
    }),
  isActive: z.enum(['active', 'inactive']),
  couponCode: z.string(),
};

const NewsOnlyFieldsSchema = {
  discountType: z.enum(COUPON_DISCOUNT_ENUM).optional(),
  discount: z.string().optional(),
  isOnetime: z.enum(['single', 'multiple']).optional(),
  maxNumberOfCoupons: z.string().nullable().optional(),
  isActive: z.enum(['active', 'inactive']).optional(),
  couponCode: z.string().optional(),
};

const CouponDiscountSchema = z
  .object({
    ...BaseCouponFieldsSchema,
    usageType: z.literal('coupon'),
    ...CouponOnlyFieldsSchema,
  })
  .refine(
    (data) => {
      if (data.isOnetime === 'multiple') {
        if (!data.couponCode || data.couponCode.trim() === '') {
          return false;
        }
      }
      return true;
    },
    {
      message: t('errorMessages.thisFieldIsRequired'),
      path: ['couponCode'],
    },
  );

const CouponNewsSchema = z.object({
  ...BaseCouponFieldsSchema,
  usageType: z.literal('news'),
  ...NewsOnlyFieldsSchema,
});

export const CouponFormSchema = z.union([
  CouponDiscountSchema,
  CouponNewsSchema,
]);

export const EventFormSchema = z.object({
  image: z.union([z.instanceof(File), z.string(), z.null()]).refine(
    (data) => {
      if (!data || data === null) {
        return false;
      }
      return true;
    },
    {
      message: t('errorMessages.thisFieldIsRequired'),
    },
  ),
  name: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  description: z.string().optional(),
  startDate: z.date({
    required_error: t('errorMessages.thisFieldIsRequired'),
  }),
  startTime: z.date({
    required_error: t('errorMessages.thisFieldIsRequired'),
  }),
  endDate: z.date({
    required_error: t('errorMessages.thisFieldIsRequired'),
  }),
  endTime: z.date({
    required_error: t('errorMessages.thisFieldIsRequired'),
  }),
  recurrenceType: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  ticketUrl: z
    .string()
    .optional()
    .refine((url) => !url || urlRegex.test(url), {
      message: t('errorMessages.invalidTicketUrl'),
    }),
  category: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
});

export const OfferFormSchema = z.object({
  name: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  offerCategory: z
    .preprocess(
      (value) => (value === '' ? undefined : value),
      z.enum([
        'categoryAlcoholCard',
        'categoryBeverageCard',
        'categoryBilards',
        'categoryBowlingLanes',
        'categoryBreakfasts',
        'categoryDesserts',
        'categoryEscapeRooms',
        'categoryGames',
        'categoryLunch',
        'categoryMenu',
        'categoryPartiesAndEvents',
        'categoryPrivateLodges',
        'categorySnacks',
        'categoryVipZone',
        'categoryOther',
      ]),
    )
    .refine((value) => !!value, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
  image: z.union([z.instanceof(File), z.string(), z.null()]).nullable(),
});

export const PlaceInformationFormSchema = z
  .object({
    image: z.union([z.instanceof(File), z.string(), z.null()]).nullable(),
    name: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    category: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    phone: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    websiteUrl: z.string().regex(urlRegex, {
      message: t('errorMessages.invalidWebsite'),
    }),
    street: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    zipCode: z.string().regex(/^\d{2}-\d{3}$/, {
      message: t('errorMessages.invalidZipCode'),
    }),
    city: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    slogan: z.string().optional(),
    description: z.string().optional(),
    additionalCategories: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
          option: z.string(),
        }),
      )
      .optional(),
    specialFeatures: z
      .array(
        z.object({
          id: z.union([z.string(), z.number()]),
          name: z.string(),
          option: z.string(),
        }),
      )
      .optional(),
    averageReceiptValue: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    isPhoneReservationsOnly: z.enum(['true', 'false']),
    minReservationTime: z.number().refine((val) => val > 0, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    maxReservationTime: z.number().refine((val) => val > 0, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    avgReservationTime: z.number().refine((val) => val > 0, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    slotInterval: z.number().refine((val) => val > 0, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    beforeReservationTime: z.number().refine((val) => val > 0, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    timezone: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    encodedName: z.string().regex(encodedNameRegex, {
      message: t('errorMessages.invalidEncodedName'),
    }),
    isChatAvailable: z.boolean(),
  })
  .refine((data) => data.minReservationTime <= data.maxReservationTime, {
    message: t('errorMessages.minGreaterThanMax'),
    path: ['minReservationTime'],
  });

export const VisibilityStepFormSchema = z
  .object({
    logo: z.union([z.instanceof(File), z.string(), z.null()]).optional(),
    slogan: z.string().max(100).optional(),
    description: z.string().max(1000).optional(),
    subcategories: z.array(z.string()).optional(),
    priceRange: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    minReservationTime: z.number().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    maxReservationTime: z.number().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    avgReservationTime: z.number().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    slotInterval: z.number().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    beforeReservationTime: z.number().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    timezone: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    encodedName: z.string().regex(encodedNameRegex, {
      message: t('errorMessages.invalidEncodedName'),
    }),
  })
  .refine((data) => data.minReservationTime <= data.maxReservationTime, {
    message: t('errorMessages.minGreaterThanMax'),
    path: ['minReservationTime'],
  });

export const GuestProfileFormSchema = z.object({
  name: z.string().regex(fullNameRegex, {
    message: t('errorMessages.invalidFullName'),
  }),
  phone: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  email: z
    .string()
    .optional()
    .refine((value) => !value || emailRegex.test(value), {
      message: t('errorMessages.invalidEmail'),
    }),
  position: z.string().refine((value) => !value || value.trim() !== '', {
    message: t('errorMessages.invalidInput'),
  }),
  companyName: z.string().refine((value) => !value || value.trim() !== '', {
    message: t('errorMessages.invalidInput'),
  }),
  tags: z.array(z.string()),
  prefferedLanguage: LanguageSelectorSchema,
  notes: z.string(),
});

export const InvoiceFormSchema = z.object({
  firstName: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  lastName: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  email: z.string().regex(emailRegex, {
    message: t('errorMessages.invalidEmail'),
  }),
  nip: z.string().regex(nipRegex, {
    message: t('errorMessages.invalidNip'),
  }),
  companyName: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  companyAddress: z.string().min(5, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
});
