import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getZonedTimeFromIsoDateAndFormat } from '../../../../functions/functions';
import { useAppSelector } from '../../../../hooks/useRedux';
import Button from '../Button/Button';
import PaymentPlanSectionSkeleton from '../../atoms/PaymentPlanSectionSkeleton/PaymentPlanSectionSkeleton';

const PaymentPlanSection = () => {
  const { t } = useTranslation();

  const placeTimezone = useAppSelector((state) => state.settings.timezone);

  // TODO: Replace dummy data with real data from backend

  const isStatusActive = true;

  const validToDate = new Date().toISOString();

  const nextPaymentDate = new Date().toISOString();

  const isLoading = false;

  return (
    <div className="flex h-fit w-full flex-col gap-4 rounded-xs border p-6">
      <p className="text-lg font-semibold">{t('messages.yourPlan')}</p>

      {isLoading ? (
        <PaymentPlanSectionSkeleton />
      ) : (
        <>
          <span className="flex flex-wrap gap-2">
            <span>
              {t('messages.status')}:{' '}
              <b
                className={clsx(
                  'font-semibold',
                  isStatusActive ? 'text-brand-green' : 'text-brand-red',
                )}
              >
                {t('messages.statusActive')}
              </b>
            </span>
            <span>
              {t('messages.validTo')}:{' '}
              <b className="font-semibold">
                {getZonedTimeFromIsoDateAndFormat(validToDate, placeTimezone)}
              </b>
            </span>
          </span>

          <span>
            {t('messages.nextPaymentWillBeTaken')}:{' '}
            <b className="font-semibold">
              {getZonedTimeFromIsoDateAndFormat(nextPaymentDate, placeTimezone)}
            </b>
          </span>
        </>
      )}
      <div className="flex justify-end">
        <Button
          type="button"
          text={t('messages.manageYourSubscription')}
          variant="secondary"
          onClick={() => {}}
          isDisabled={isLoading}
          isSkeleton={isLoading}
        />
      </div>
    </div>
  );
};

export default PaymentPlanSection;
