import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { IInvoiceModalProps } from './InvoiceModal.types';
import Modal from '../../molecules/Modal/Modal';
import { TInvoiceFormValues } from '../../../../types/IInvoiceFormValues';
import { InvoiceFormSchema } from '../../../../schema/FormsSchemas';
import Input from '../../molecules/Input/Input';
import ModalContent from '../../atoms/ModalContent/ModalContent';
import Button from '../../molecules/Button/Button';

const InvoiceModal: FC<IInvoiceModalProps> = ({ visible, onClose }) => {
  const { t } = useTranslation();

  // TODO: Replace dummy data with real data from backend

  const isLoading = false;

  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TInvoiceFormValues>({
    resolver: zodResolver(InvoiceFormSchema),
  });

  const onSubmit: SubmitHandler<TInvoiceFormValues> = ({
    firstName,
    lastName,
    email,
    nip,
    companyAddress,
    companyName,
  }) => {};

  const formatNIP = (value: string) => {
    const digits = value.replace(/\D/g, '');

    if (digits.length <= 3) return digits;
    if (digits.length <= 6) return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    if (digits.length <= 8) {
      return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
    }
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(
      6,
      8,
    )}-${digits.slice(8, 10)}`;
  };

  useEffect(() => {
    if (!visible) {
      clearErrors();
      reset();
    }
  }, [clearErrors, reset, visible]);

  return (
    <Modal
      title={t('messages.editInvoiceData')}
      visible={visible}
      onClose={onClose}
    >
      <form className="w-full pt-4" onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <div className="flex flex-col gap-2 md:flex-row md:gap-4">
            <Controller
              control={control}
              name="firstName"
              defaultValue=""
              render={({ field: { value, name, onChange } }) => (
                <Input
                  name={name}
                  type="text"
                  variant="secondary"
                  labelText={t('messages.firstName')}
                  placeholder={t('messages.typeFirstName')}
                  value={value}
                  onChange={onChange}
                  error={errors.firstName?.message}
                  maxLength={100}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              defaultValue=""
              render={({ field: { value, name, onChange } }) => (
                <Input
                  name={name}
                  type="text"
                  variant="secondary"
                  labelText={t('messages.lastName')}
                  placeholder={t('messages.typeLastName')}
                  value={value}
                  onChange={onChange}
                  error={errors.lastName?.message}
                  maxLength={100}
                />
              )}
            />
          </div>
          <div className="mt-4 flex flex-col gap-2 md:flex-row md:gap-4">
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={({ field: { value, name, onChange } }) => (
                <Input
                  name={name}
                  type="text"
                  variant="secondary"
                  labelText={t('messages.email')}
                  placeholder={t('messages.typeEmailAddress')}
                  value={value}
                  onChange={onChange}
                  error={errors.email?.message}
                  maxLength={100}
                />
              )}
            />
            <Controller
              control={control}
              name="nip"
              defaultValue=""
              render={({ field: { value, name, onChange } }) => (
                <Input
                  name={name}
                  type="text"
                  variant="secondary"
                  labelText="NIP"
                  placeholder={t('messages.typeCategoryName')}
                  value={value}
                  onChange={(e) => onChange(formatNIP(e.target.value))}
                  error={errors.nip?.message}
                  maxLength={13}
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name="companyName"
            defaultValue=""
            render={({ field: { value, name, onChange } }) => (
              <Input
                name={name}
                type="text"
                variant="secondary"
                labelText={t('messages.companyName')}
                placeholder={t('messages.typeCompanyName')}
                value={value}
                onChange={onChange}
                error={errors.companyName?.message}
                maxLength={500}
              />
            )}
          />
          <Controller
            control={control}
            name="companyAddress"
            defaultValue=""
            render={({ field: { value, name, onChange } }) => (
              <Input
                name={name}
                type="text"
                variant="secondary"
                labelText={t('messages.companyAddress')}
                placeholder={t('messages.typeCompanyAddress')}
                value={value}
                onChange={onChange}
                error={errors.companyAddress?.message}
                maxLength={500}
              />
            )}
          />
        </ModalContent>
        <div className="mt-2 flex justify-end gap-4">
          <Button
            type="submit"
            text={t('messages.updateData')}
            variant="secondary"
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </div>
      </form>
    </Modal>
  );
};

export default InvoiceModal;
