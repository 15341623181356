import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import {
  IAcceptCouponThunk,
  ICreateCouponThunk,
  IGetCouponThunk,
  IListCouponsCountThunk,
  IListCouponThunk,
  IScannedCouponThunk,
  ISingleCouponThunk,
  IUpdateCouponThunk,
  TCoupon,
} from '../../types/Coupon';
import {
  getBunnyImage,
  getFileCdnFromStorageLink,
  removeImageFromStorage,
  uploadPhoto,
} from '../../functions/storage';
import { REST_API_URLS } from '../../constants/constants';
import { errorSchema } from '../../schema/ErrorSchemas';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { ImageCompressionError } from '../../types/Storage';
import {
  AcceptCouponResponseSchema,
  CreateCouponResponseSchema,
  DeleteCouponResponseSchema,
  GetCouponResponseSchema,
  listCouponsCountResponseValidateSchema,
  ListCouponsResponseSchema,
  ScannedCouponResponseSchema,
} from '../../schema/CouponSchemas';
import {
  formatDiscountValue,
  handleForbiddenError,
} from '../../functions/functions';

export const listCouponThunk = createAsyncThunk(
  'coupon/listCouponThunk',
  async (
    {
      placeId,
      limit,
      offset,
      nextId,
      previousId,
      nextName,
      previousName,
      sortBy,
      order,
      category,
    }: IListCouponThunk,
    { rejectWithValue },
  ) => {
    try {
      const params = {
        placeId,
        limit,
        offset,
        ...(nextId !== undefined && { nextId }),
        ...(previousId !== undefined && { previousId }),
        ...(nextName !== undefined && { nextName }),
        ...(previousName !== undefined && { previousName }),
        ...(sortBy !== undefined && sortBy !== '' && { sortBy }),
        ...(order !== undefined && order !== '' && { order }),
        ...(category !== undefined && { isActive: category }),
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.listCoupons,
        method: 'GET',
        params,
      });

      const validatedResponse = ListCouponsResponseSchema.parse(response);

      const coupons = validatedResponse.data.map((coupon: TCoupon) => ({
        ...coupon,
        image: getBunnyImage(coupon.image, '300', '300'),
      }));

      return {
        data: coupons,
        total: validatedResponse.total,
      };
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingCoupons'));
      return rejectWithValue(error);
    }
  },
);

export const getCouponThunk = createAsyncThunk(
  'coupon/getCouponThunk',
  async ({ couponId, placeId }: IGetCouponThunk, { rejectWithValue }) => {
    try {
      const params = {
        couponId,
        placeId,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.getCoupon,
        method: 'GET',
        params,
      });

      const validatedResponse = GetCouponResponseSchema.parse(response);

      const coupon = validatedResponse.data;
      coupon.image = getBunnyImage(coupon.image, '300', '300');

      return coupon;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringFetchingCoupon'));
      return rejectWithValue(error);
    }
  },
);

export const createCouponThunk = createAsyncThunk(
  'coupon/createCouponThunk',
  async (
    {
      placeId,
      name,
      discount,
      description,
      image,
      isOnetime,
      isForAdults,
      categories,
      couponCode,
      startDate,
      endDate,
      isActive,
      maxNumberOfCoupons,
      callback,
      path,
      selectedListVariant,
      discountType,
      isDiscount,
    }: ICreateCouponThunk,
    { rejectWithValue },
  ) => {
    try {
      const uploadedImage = await uploadPhoto(image, path);
      const imageObject = {
        photoUrl: uploadedImage.photoUrl,
      };

      const data = {
        placeId,
        name,
        discount: formatDiscountValue(discount, discountType),
        description,
        image: imageObject.photoUrl,
        isOnetime,
        isForAdults,
        categories,
        couponCode,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        isActive,
        limit: maxNumberOfCoupons,
        discountType,
        isDiscount,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.createCoupon,
        method: 'POST',
        data,
      });

      callback();

      const validatedResponse = CreateCouponResponseSchema.parse(response);

      return {
        coupon: {
          ...validatedResponse.data,
          image: getBunnyImage(data.image, '300', '300'),
        },
        selectedListVariant,
      };
    } catch (error) {
      if (error instanceof ImageCompressionError) {
        toast.error(
          t('errorMessages.somethingWentWrongDuringCompressingImage'),
        );
        return rejectWithValue(ImageCompressionError);
      }
      if (error instanceof AxiosError) {
        const parsedError = errorSchema.safeParse(error.response?.data);

        if (!parsedError.success) {
          toast.error(
            t('errorMessages.somethingWentWrongDuringCreatingCoupon'),
          );
          return rejectWithValue(error);
        }

        const { errorCode } = parsedError.data;
        if (errorCode === 'NAME_TAKEN') {
          toast.error(t('messages.couponNameAlreadyExists'));
        } else if (errorCode === 'CODE_TAKEN') {
          toast.error(t('messages.couponCodeAlreadyExists'));
        }
        handleForbiddenError(error);
      } else {
        toast.error(t('errorMessages.somethingWentWrongDuringCreatingCoupon'));
        return rejectWithValue(error);
      }

      return rejectWithValue(error);
    }
  },
);

export const updateCouponThunk = createAsyncThunk(
  'coupon/updateCouponThunk',
  async (
    {
      id,
      placeId,
      name,
      discount,
      description,
      image,
      oldImageUrl,
      startDate,
      endDate,
      isOnetime,
      couponCode,
      callback,
      isForAdults,
      categories,
      path,
      isActive,
      maxNumberOfCoupons,
      selectedListVariant,
      discountType,
      isDiscount,
    }: IUpdateCouponThunk,
    { rejectWithValue },
  ) => {
    try {
      const imageObject = {
        photoUrl: oldImageUrl,
      };

      if (image instanceof File) {
        removeImageFromStorage(oldImageUrl, 'coupons');
        const uploadedImage = await uploadPhoto(image, path);
        imageObject.photoUrl = uploadedImage.photoUrl;
      } else {
        imageObject.photoUrl = getFileCdnFromStorageLink(oldImageUrl);
      }

      const data = {
        id,
        placeId,
        name,
        discount: formatDiscountValue(discount, discountType),
        description,
        image: imageObject.photoUrl,
        isOnetime,
        isForAdults,
        categories,
        couponCode,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        isActive,
        limit: maxNumberOfCoupons,
        discountType,
        isDiscount,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.updateCoupon,
        method: 'PATCH',
        data,
      });

      const validatedResponse = CreateCouponResponseSchema.parse(response);

      callback();

      return {
        coupon: {
          ...validatedResponse.data,
          image: getBunnyImage(data.image ?? '', '300', '300'),
        },
        selectedListVariant,
      };
    } catch (error) {
      if (error instanceof ImageCompressionError) {
        toast.error(
          t('errorMessages.somethingWentWrongDuringCompressingImage'),
        );
        return rejectWithValue(ImageCompressionError);
      }
      if (error instanceof AxiosError) {
        const parsedError = errorSchema.safeParse(error.response?.data);

        if (!parsedError.success) {
          toast.error(
            t('errorMessages.somethingWentWrongDuringUpdatingCoupon'),
          );
          return rejectWithValue(error);
        }

        const { errorCode } = parsedError.data;

        if (errorCode === 'NAME_TAKEN') {
          toast.error(t('messages.couponNameAlreadyExists'));
        } else if (errorCode === 'CODE_TAKEN') {
          toast.error(t('messages.couponCodeAlreadyExists'));
        } else if (errorCode === 'LIMIT_EXCEEDED') {
          toast.error(t('errorMessages.couponLimitExceeded'));
        }
        handleForbiddenError(error);
      } else {
        toast.error(t('errorMessages.somethingWentWrongDuringUpdatingCoupon'));
        return rejectWithValue(error);
      }

      return rejectWithValue(error);
    }
  },
);

export const deleteCouponThunk = createAsyncThunk(
  'coupon/deleteCouponThunk',
  async (
    { placeId, couponId, oldImageUrl }: ISingleCouponThunk,
    { rejectWithValue },
  ) => {
    try {
      const data = {
        placeId,
        couponId,
        oldImageUrl,
      };

      removeImageFromStorage(oldImageUrl, 'coupons');

      const response = await axiosMiddleware({
        url: REST_API_URLS.deleteCoupon,
        method: 'DELETE',
        data,
      });

      DeleteCouponResponseSchema.parse(response);

      return couponId;
    } catch (error) {
      handleForbiddenError(
        error,
        t('errorMessages.somethingWentWrongDuringDeletingCoupon'),
      );
      return rejectWithValue(error);
    }
  },
);

export const scanCouponThunk = createAsyncThunk(
  'coupon/scanCouponThunk',
  async (
    {
      placeId,
      couponCode,
      success,
      alreadyUsed,
      notFound,
      notValid,
      inactive,
      notStarted,
    }: IScannedCouponThunk,
    { rejectWithValue },
  ) => {
    try {
      const params = {
        placeId,
        couponCode,
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.scanCoupon,
        method: 'GET',
        params,
      });

      const validatedResponse = ScannedCouponResponseSchema.parse(response);

      success();

      return validatedResponse.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data;

        const parsedError = errorSchema.safeParse(errorData);

        if (!parsedError.success) {
          toast.error(
            t('errorMessages.somethingWentWrongDuringFetchingCoupon'),
          );
          return rejectWithValue(error);
        }

        const { errorCode } = parsedError.data;

        if (errorCode === 'NOT_FOUND') {
          notFound();
        } else if (errorCode === 'EXPIRED') {
          alreadyUsed();
        } else if (errorCode === 'NOT_VALID') {
          notValid();
        } else if (errorCode === 'COUPON_INACTIVE') {
          inactive();
        } else if (errorCode === 'NOT_STARTED') {
          notStarted();
        } else if (errorCode === 'LIMIT_EXCEEDED') {
          toast.error(t('errorMessages.couponLimitExceeded'));
        }
        handleForbiddenError(error);
      } else {
        toast.error(t('errorMessages.somethingWentWrongDuringFetchingCoupon'));
        return rejectWithValue(error);
      }

      return rejectWithValue(error);
    }
  },
);

export const acceptCouponThunk = createAsyncThunk(
  'coupon/acceptCouponThunk',
  async (
    {
      placeId,
      couponId,
      couponUsesId,
      callback,
      isOnetime,
    }: IAcceptCouponThunk,
    { rejectWithValue },
  ) => {
    try {
      const data = {
        placeId,
        couponId,
        isOnetime,
        ...(couponUsesId !== undefined && { couponUsesId }),
      };

      const response = await axiosMiddleware({
        url: REST_API_URLS.acceptCoupon,
        method: 'PATCH',
        data,
      });

      AcceptCouponResponseSchema.parse(response);

      callback();

      return couponId;
    } catch (error) {
      toast.error(t('errorMessages.somethingWentWrongDuringAcceptingCoupon'));
      return rejectWithValue(error);
    }
  },
);

export const listCouponsCountThunk = createAsyncThunk(
  'coupon/listCouponsCount',
  async ({ isActive }: IListCouponsCountThunk, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.listCouponsCount,
        method: 'GET',
        params: {
          isActive,
        },
      });

      listCouponsCountResponseValidateSchema.parse(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
